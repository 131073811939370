<template>
  <MedicineTable />
</template>

<script>
import MedicineTable from '@/components/dict/medicine/MedicineTable'
export default {
  name: 'ReferencesMedicine',
  components: { MedicineTable },
}
</script>
