<template>
  <v-btn @click="loadManual" :loading="loading">
    <v-icon class="mr-2" color="green darken-4">mdi-microsoft-excel</v-icon>
    Выгрузить справочник
  </v-btn>
</template>

<script>
//компонент для выгрузки справочников КЭИ и АВБ
import Excel from '@/lib/excel'
import { mapMutations } from 'vuex'

export default {
  name: 'ExportManual',
  data: () => ({
    loading: false,
    maxFilterCount: 1,
  }),
  props: {
    storedData: {
      type: Array,
      required: true,
    },
    hideMatched: {
      type: Boolean,
      default: false,
    },
    classManual: {
      type: String,
      default: 'КЭИ_АВБ',
    },
  },
  computed: {
    //Показывать столбец соответствует/не соответствует
    showColumnMatched() {
      return !this.hideMatched
        ? [
            {
              name: 'is_matched',
              label:
                'Оценка соответствия требованиям (соответствует/не соответствует)',
              options: {
                width: 22,
                style: {
                  alignment: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                },
              },
            },
          ]
        : []
    },
    //Динамически добавляем новые столбцы answer,questions в зависимости от их максимального количества
    allFilters() {
      const filters = []
      for (let i = 1; i <= this.maxFilterCount; i++) {
        filters.push(
          {
            name: `question${i}`,
            label: `Вопрос ${i}`,
            options: {
              width: 20,
              style: {
                font: { bold: true },
                alignment: { vertical: 'top' },
              },
            },
          },
          {
            name: `answer${i}`,
            label: `Ответ ${i}`,
            options: {
              width: 20,
              style: {
                alignment: { vertical: 'top' },
              },
            },
          }
        )
      }
      return filters
    },
  },
  methods: {
    ...mapMutations(['SET_ERROR']),

    async loadManual() {
      this.loading = true
      const changedStoreData = JSON.parse(JSON.stringify(this.storedData))
      changedStoreData.forEach(el => {
        el.criteria = `${el.order}` + '. ' + el.criteria

        if (el.data) {
          el.data.filter?.forEach((item, idx) => {
            el[`question${idx + 1}`] = item.question
            el[`answer${idx + 1}`] = item.answer
          })
          this.maxFilterCount = Math.max(
            this.maxFilterCount,
            el.data.filter?.length
          )
        }
      })
      try {
        await Excel.downloadXLSX({
          name: 'Лист 1',
          data: async () => {
            return changedStoreData
          },
          headers: [
            {
              name: 'criteria',
              label: 'Критерий',
              options: {
                width: 50,
                style: {
                  alignment: {
                    vertical: 'top',
                  },
                },
              },
            },
            ...this.showColumnMatched,
            {
              name: 'template',
              label:
                'Обоснование результатов оценки методологического качества',
              options: {
                width: 100,
                style: {
                  alignment: { wrapText: true, vertical: 'top' },
                },
              },
            },
            ...this.allFilters,
          ],
          fileName: `${
            this.classManual
          }_шаблонные_фразы_от_${this.getTimestamp()}`,
          // итератор строк данных
          rowIterator: this.hideMatched
            ? undefined
            : (_, row, dataRow) => {
                if (dataRow.is_matched === 'Соответствует') {
                  // 2 столбец "соответствует/не соответствует"
                  row.getCell(2).font = { bold: true }
                }
              },
        })
      } catch (e) {
        this.SET_ERROR({
          head: this.classManual,
          text: `Выгрузка справочника шаблонных фраз ${this.classManual}`,
          e,
        })
      } finally {
        this.loading = false
        this.maxFilterCount = 1
      }
    },
    getTimestamp() {
      const date = new Date()
      const addZero = v => (v.toString().length === 1 ? `0${v}` : v)
      const yyyy = date.getFullYear()
      const MM = addZero(date.getMonth() + 1)
      const dd = addZero(date.getDate())
      return `${dd}_${MM}_${yyyy}`
    },
  },
}
</script>
