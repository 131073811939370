<template>
  <v-container fluid>
    <v-card flat>
      <ReferenceTip
        class="py-5 mt-2"
        v-if="companies && isManager"
        label="Конфликт интересов. Добавьте сотрудников в компанию для исключения их из перечня подписантов для предложений PLP"
      />
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Поиск по компаниям"
          single-line
          clearable
          hide-details
        />
      </v-card-title>

      <!-- Таблица с данными -->
      <v-data-table
        :items="companies"
        :headers="headers"
        :search="search"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :show-expand="isManager"
        hide-default-footer
        disable-pagination
        item-key="id"
        no-results-text="Ничего не нашлось"
        :loading="!companiesReady"
        loading-text="Список компаний загружается..."
      >
        <template v-slot:top v-if="isManager">
          <div class="d-flex justify-end">
            <v-btn @click="openForAdd" color="success">
              <v-icon class="mr-2">mdi-plus</v-icon> Добавить компанию
            </v-btn>
          </div>
        </template>

        <template v-slot:[`item.id`]="{ index }">
          <span>{{ index + 1 }}.</span>
        </template>
        <template v-slot:[`item.name`]="{ value }">
          <span :inner-html.prop="value | highlight(search)" />
        </template>
        <template v-slot:[`item.description`]="{ value }">
          <span :inner-html.prop="value | highlight(search)" />
        </template>
        <!-- Слот - действия в таблице компаний (редактировать / удалить) -->
        <template v-slot:[`item.actions`]="{ item }" v-if="isManager">
          <div class="actions">
            <v-btn
              @click="openForEdit(item)"
              fab
              small
              depressed
              title="Редактировать описание компании"
              color="transparent"
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
          </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }" v-if="isManager">
          <td :colspan="headers.length">
            <CompanyUsersTable :company="item" />
          </td>
        </template>
      </v-data-table>
    </v-card>

    <CompanyForm
      :company="item"
      v-if="showCompanyForm"
      @close-company-form="closeCompanyForm"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ReferenceTip from '@/components/ReferenceTip'
import CompanyForm from '@/components/dict/companies/allForms/CompanyForm'
import CompanyUsersTable from '@/components/dict/companies/CompanyUsersTable'

export default {
  components: {
    CompanyUsersTable,
    CompanyForm,
    ReferenceTip,
  },
  data: () => ({
    headers: [
      {
        text: '№',
        value: 'id',
        width: '5px',
        cellClass: 'text--disabled',
        sortable: false,
        filterable: false,
      },
      {
        text: 'Название',
        value: 'name',
        cellClass: 'font-weight-medium',
      },
      { text: 'Описание', value: 'description' },
      { text: 'Действия', value: 'actions', width: '10px', sortable: false },
      { text: '', value: 'data-table-expand', sortable: false },
    ],
    companiesReady: false,
    item: null, // компания как пропс для формы с компаниями
    search: '', // строка поиска по компаниям
    showCompanyForm: false, // показать форму добавления\редактирования компании
    expanded: [], // переменная, необходимая для работы expand у таблицы
    singleExpand: false, // одну строку открыл, вторая закрылась
  }),
  created() {
    this.get_companies().then(() => {
      this.companiesReady = true
    })
    if (this.isManager) {
      this.get_users()
    } else {
      this.headers = this.headers.slice(0, 3)
      this.expanded = []
    }
  },
  computed: {
    ...mapGetters(['companiesList', 'usersList', 'isManager']),
    companies() {
      return this.companiesList
    },
  },
  methods: {
    ...mapActions(['get_companies', 'get_users']),
    openForAdd() {
      this.showCompanyForm = true
    },
    openForEdit(item) {
      this.item = item
      this.showCompanyForm = true
    },
    closeCompanyForm() {
      this.item = null
      this.showCompanyForm = false
    },
    closeAddUserDialog() {
      this.openForAddUser = false
      this.selectedUser = null
    },
  },
}
</script>

<style scoped>
.v-data-table >>> tr .actions {
  display: none !important;
}
.v-data-table >>> tr:hover .actions {
  display: flex !important;
}
</style>
