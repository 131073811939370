<template>
  <CompaniesPage />
</template>

<script>
import CompaniesPage from '@/components/dict/companies/CompaniesTable'

export default {
  name: 'ReferencesCompanies',
  components: { CompaniesPage },
}
</script>
