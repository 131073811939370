import { render, staticRenderFns } from "./ReferencesSummary.vue?vue&type=template&id=45708756&scoped=true&"
import script from "./ReferencesSummary.vue?vue&type=script&lang=js&"
export * from "./ReferencesSummary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45708756",
  null
  
)

export default component.exports