<template>
  <v-container class="pt-0">
    <v-toolbar flat height="15">
      <template v-slot:extension>
        <v-tabs v-model="activeTab" centered center-active show-arrows>
          <v-tab
            v-for="tabs in refTabs"
            :key="tabs.name"
            :to="{ name: tabs.name }"
          >
            <v-icon v-if="tabs.meta.icon" left>{{ tabs.meta.icon }}</v-icon>
            {{ tabs.meta.title }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <keep-alive>
      <transition name="fade">
        <router-view />
      </transition>
    </keep-alive>
  </v-container>
</template>

<script>
export default {
  name: 'ReferencesPage',
  data: () => ({
    activeTab: null,
  }),
  computed: {
    refMain() {
      const refMain = this.$router.options.routes.find(
        route => route.name === 'ReferencesPage'
      )
      return refMain || {}
    },
    refTabs() {
      return this.refMain?.children || []
    },
  },
}
</script>
