<template>
  <v-dialog width="700px" persistent :value="true">
    <v-card class="pb-2">
      <v-card-title> Добавить пользователей в компанию </v-card-title>
      <v-card-text>
        <AutocompleteChoiceUser
          auto-select-first
          v-model="selectedUserId"
          :items="users"
          item-value="id"
          item-text="fio"
          :label="selectedUserId === null ? 'Выберите пользователя' : ''"
          return-object
        />
        <!--
          :error-messages="
            error_message !== ''
            ? 'Произошла ошибка добавления пользователя в компанию'
            : ''
          "
          return-object
          dense
        -->
        <v-alert v-if="error_message" type="error" outlined dense dismissible>
          Произошла ошибка добавления пользователя в компанию
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="addUserToCompany"
          color="primary"
          small
          :disabled="addUserInProgress"
          >Добавить</v-btn
        >
        <v-btn @click="closeAddUserDialog" small>Отменить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AutocompleteChoiceUser from '@/components/editors/AutocompleteChoiceUser'

export default {
  name: 'AddCompanyUserForm',
  components: { AutocompleteChoiceUser },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedUserId: null,
    addUserInProgress: false,
    error_message: '',
  }),
  computed: {
    ...mapGetters(['usersList', 'companiesList']),
    users() {
      // юзеры отдельной компании, только той, которая сейчас открыта
      const companyUsers = this.companiesList.find(
        company => company.id === this.company.id
      )?.users
      // разрешаем добавить тех, которых там НЕТ
      return companyUsers
        ? this.usersList.filter(
            user => companyUsers.findIndex(({ id }) => id === user.id) === -1
          )
        : this.usersList
    },
  },
  methods: {
    ...mapActions(['add_user_to_company']),
    async addUserToCompany() {
      // добавляем пользователя в компанию по userId, companyId
      this.addUserInProgress = true
      try {
        const companyId = this.company.id
        const userId = this.selectedUserId
        await this.add_user_to_company({ companyId, userId })
        this.closeAddUserDialog()
      } catch (err) {
        this.error_message = err
      } finally {
        this.addUserInProgress = false
      }
    },
    closeAddUserDialog() {
      this.$emit('close-add-user-dialog')
    },
  },
}
</script>
