<template>
  <v-container fluid>
    <v-card flat ref="mainManual">
      <div class="text-start v-btn--absolute" v-if="isManager">
        <v-icon small color="green darken-2"> mdi-arrow-down </v-icon>
        <span
          class="tip pointer"
          @click="$vuetify.goTo($refs.addManual, options)"
          >к справочнику доп.КЭИ</span
        >
      </div>
      <div class="text-end" v-if="isManager">
        <span class="tip pointer" @click="changeOpen">{{ openText }}</span>
      </div>
      <ReferenceTip v-if="isManager" class="mb-3 mt-1">
        Выгрузка справочника в формате Excel доступна внизу таблицы, кнопка
        "Выгрузить справочник"
      </ReferenceTip>
      <v-expand-transition v-if="isManager">
        <div v-show="isOpen">
          <ReferenceTip
            >Для обновления справочника необходимо выбрать файл (*.xlsx), нажать
            "Обновить справочник"<br />В результате обновления произойдет полная
            замена текущего справочника</ReferenceTip
          >

          <UpdateManual />
        </div>
      </v-expand-transition>
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Поиск по критерию"
          single-line
          clearable
          hide-details
        ></v-text-field>
      </v-card-title>

      <!-- Таблица с данными -->
      <v-data-table
        dense
        :search="search"
        :headers="headers"
        :items="changedData"
        :group-by="['order', 'criteria']"
        :items-per-page="-1"
        :footerProps="table_footer_options"
        :loading="loading"
        loading-text="Справочник загружается"
      >
        <!-- Строка группировки -->
        <template #[`group.header`]="{ group, headers, items }">
          <td :colspan="headers.length">
            <span v-if="items" class="text-overline"
              >{{ items[0].order }}.
              <span
                :inner-html.prop="
                  items[0].criteria | highlight(search)
                " /></span
            ><span v-else>{{ group }}</span>
          </td>
        </template>

        <template #[`item.index`]="{ value }">
          <span>{{ value }}.</span>
        </template>
        <template #[`item.criteria`]="{ value }">
          <span :inner-html.prop="value | highlight(search)" />
        </template>
        <template #[`item.template`]="{ value, item }">
          <div>
            <span :inner-html.prop="value | highlight(search)" />
          </div>
          <div>
            <span :inner-html.prop="getFiltersList(item)" />
          </div>
        </template>
        <template v-slot:footer v-if="isManager">
          <div class="d-flex align-end justify-end">
            <ExportManual
              class="my-3"
              v-if="changedData && !loading"
              :storedData="changedData"
              :class-manual="'КЭИ'"
            />
          </div>
        </template>
      </v-data-table>
    </v-card>
    <!-- КЭИ - дополнительный справочник -->
    <v-divider class="my-3" />
    <span class="mt-2 text-h6">КЭИ - дополнительно</span>
    <v-card flat ref="addManual">
      <div class="text-start v-btn--absolute" v-if="isManager">
        <v-icon small color="green darken-2"> mdi-arrow-up </v-icon>
        <span
          class="tip pointer"
          @click="$vuetify.goTo($refs.mainManual, options)"
          >к справочнику КЭИ</span
        >
      </div>
      <div class="text-end" v-if="isManager">
        <span class="tip pointer" @click="changeAddOpen">{{
          openAddText
        }}</span>
      </div>
      <ReferenceTip v-if="isManager" class="mb-3 mt-1">
        Выгрузка дополнительного справочника в формате Excel доступна внизу
        таблицы, кнопка "Выгрузить доп. справочник"
      </ReferenceTip>
      <v-expand-transition v-if="isManager">
        <div v-show="isAddOpen">
          <ReferenceTip
            >Для обновления доп. справочника необходимо выбрать файл (*.xlsx),
            нажать "Обновить справочник"<br />В результате обновления произойдет
            полная замена текущего справочника</ReferenceTip
          >

          <UpdateAddManual />
        </div>
      </v-expand-transition>
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="addSearch"
          prepend-inner-icon="mdi-magnify"
          label="Поиск по критерию"
          single-line
          clearable
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        :headers="addHeaders"
        :items="changedAddData"
        :items-per-page="-1"
        hide-default-footer
        :group-by="['criteria']"
        :loading="loading"
        loading-text="Справочник загружается"
        :search="addSearch"
      >
        <!--        ГРУППИРОВКА-->
        <template #[`group.header`]="{ group, headers, items }">
          <td :colspan="headers.length">
            <span v-if="items" class="text-overline"
              >{{ items[0].order }}.
              <span
                :inner-html.prop="
                  items[0].criteria | highlight(addSearch)
                " /></span
            ><span v-else>{{ group }}</span>
          </td>
        </template>
        <template #[`item.index`]="{ value }">
          <span>{{ value }}.</span>
        </template>
        <template #[`item.template`]="{ value, item }">
          <div>
            <span :inner-html.prop="value | highlight(addSearch)" />
          </div>
          <div>
            <span :inner-html.prop="getAddFiltersList(item)" />
          </div>
        </template>

        <template v-slot:footer v-if="isManager">
          <div class="d-flex align-end justify-end">
            <ExportManual
              hide-matched
              class="my-3"
              v-if="changedAddData && !loading"
              :storedData="changedAddData"
              :class-manual="'ДОП_КЭИ'"
            />
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ReferenceTip from '../../ReferenceTip'
import ExportManual from '@/components/dict/ExportManual'
import UpdateManual from './allForms/UpdateManual'
import UpdateAddManual from '@/components/dict/kei/allForms/UpdateAddManual'
import { setIndexBy } from '@/lib/objects'
import { EX_GET_CORRESPOND } from '../../expAvbKei/const'

export default {
  name: 'KeiTable',
  components: {
    UpdateAddManual,
    ExportManual,
    ReferenceTip,
    UpdateManual,
  },
  data() {
    return {
      loading: true,
      search: '',
      addSearch: '',
      sheets: [],
      isOpen: false,
      isAddOpen: false,
      addHeaders: [
        {
          text: '№',
          value: 'index',
          width: '5px',
          sortable: false,
          cellClass: 'text--disabled',
          filterable: false,
        },
        {
          text: 'Критерий',
          value: 'criteria',
          width: '300px',
          sortable: false,
          cellClass: 'text--disabled',
          filterable: false,
        },
        {
          text: 'Шаблон',
          align: 'justify',
          value: 'template',
          cellClass: 'py-2 red-line',
        },
      ],
      headers: [
        {
          text: '№',
          value: 'index',
          width: '5px',
          sortable: false,
          cellClass: 'text--disabled',
          filterable: false,
        },
        {
          text: 'Критерий ',
          value: 'criteria',
          width: '100px',
          sortable: true,
        },
        {
          text: 'Оценка соответствия',
          value: 'is_matched',
          width: '150px',
          align: 'center',
          cellClass: 'text--secondary',
          sortable: false,
        },
        {
          text: 'Шаблон обоснование результатов оценки методологического качества',
          align: 'justify',
          value: 'template',
          cellClass: 'py-2 red-line',
        },
      ],
      table_footer_options: {
        itemsPerPageOptions: [20, 50, 100, -1],
      },
    }
  },

  async mounted() {
    this.loading = true
    try {
      await Promise.all([
        this.LOAD_KEI_TEMPLATE(),
        this.LOAD_KEI_CRITERIA(),
        this.LOAD_ADD_KEI_TEMPLATE(),
        this.LOAD_ADD_KEI_CRITERIA(),
      ])
    } finally {
      this.loading = false
    }
  },

  computed: {
    ...mapGetters([
      'isManager',
      'storedKeiTemplate',
      'storedKeiCriteria',
      'storedAddKeiTemplate',
      'storedAddKeiCriteria',
    ]),
    //options для скролла до справочника
    options() {
      return {
        duration: 500,
        offset: 70,
        easing: 'easeInOutCubic',
      }
    },
    openText() {
      return this.isOpen ? 'Скрыть' : 'Обновить справочник'
    },
    openAddText() {
      return this.isAddOpen ? 'Скрыть' : 'Обновить доп. справочник'
    },
    changedData() {
      if (!this.storedKeiCriteria || !this.storedKeiTemplate) return []
      const outTable = []
      this.storedKeiCriteria.forEach(({ order, name, id }) => {
        this.storedKeiTemplate
          .filter(({ dict_criterion_id }) => dict_criterion_id === id)
          .forEach(({ is_matched, template, data }) => {
            outTable.push({
              order,
              criteria: name,
              is_matched: EX_GET_CORRESPOND(is_matched),
              template,
              data,
            })
          })
      })
      return setIndexBy(outTable, 'order')
    },
    //Данные для доп справочника КЕИ
    changedAddData() {
      if (!this.storedAddKeiCriteria || !this.storedAddKeiTemplate) return []
      const outAddTable = []
      this.storedAddKeiTemplate.forEach(
        ({ dict_criterion_id, template, data }) => {
          this.storedAddKeiCriteria.forEach(({ order, name: criteria, id }) => {
            if (dict_criterion_id === id) {
              outAddTable.push({
                order,
                criteria,
                template,
                data,
              })
            }
          })
        }
      )
      return setIndexBy(outAddTable, 'order')
    },
  },

  methods: {
    ...mapActions([
      'LOAD_KEI_TEMPLATE',
      'LOAD_KEI_CRITERIA',
      'LOAD_ADD_KEI_TEMPLATE',
      'LOAD_ADD_KEI_CRITERIA',
    ]),
    changeOpen() {
      this.isOpen = !this.isOpen
    },
    changeAddOpen() {
      this.isAddOpen = !this.isAddOpen
    },
    getFiltersList(item) {
      let listString = ''
      item?.data?.filter?.forEach(el => {
        listString += ` <span class="font-weight-bold">${el.question}</span>  [<span class="font-weight-bold teal--text">${el.answer}</span>]`
      })
      return listString || ''
    },
    getAddFiltersList(item) {
      let listString = ''
      item?.data?.filter?.forEach(el => {
        listString += ` <span class="font-weight-bold">${el.question}</span>  [<span class="font-weight-bold teal--text">${el.answer}</span>]`
      })
      return listString || ''
    },
  },
}
</script>
