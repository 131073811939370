<template>
  <v-container>
    <v-card flat ref="mainManual">
      <div class="text-start v-btn--absolute" v-if="isManager">
        <v-icon small color="green darken-2"> mdi-arrow-down </v-icon>
        <span
          class="tip pointer"
          @click="$vuetify.goTo($refs.addManual, options)"
          >к справочнику экспертных организаций</span
        >
      </div>
      <div class="text-end" v-if="isManager">
        <span class="tip pointer" @click="changeOpen">{{ openGvsText }}</span>
      </div>
      <ReferenceTip v-if="isManager" class="mb-3 mt-1">
        Выгрузка справочника ГВС в формате Excel доступна внизу таблицы, кнопка
        "Выгрузить справочник"
      </ReferenceTip>
      <v-expand-transition v-if="isManager">
        <div v-show="isOpen">
          <ReferenceTip
            >Для обновления справочника ГВС необходимо выбрать файл (*.xlsx),
            нажать "Обновить справочник"<br />В результате обновления произойдет
            полная замена текущего справочника</ReferenceTip
          >

          <UpdateGvsManual />
        </div>
      </v-expand-transition>
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Поиск по ФИО"
          single-line
          clearable
          hide-details
        ></v-text-field>
      </v-card-title>

      <!-- Таблица с данными -->
      <v-data-table
        dense
        :search="search"
        :headers="gvsHeaders"
        :items="changedGVS"
        :items-per-page="-1"
        :footerProps="table_footer_options"
        :loading="loading"
        loading-text="Справочник загружается"
      >
        <template #[`item.fio`]="{ value }">
          <span :inner-html.prop="value | highlight(search)" />
        </template>
        <template v-slot:footer v-if="isManager">
          <div class="d-flex align-end justify-end">
            <ExportSummary
              class="my-3"
              v-if="dictGvs && !loading"
              :storedData="dictGvs"
            />
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-divider class="my-3" />
    <span class="mt-2 text-h6">Список экспертных организаций</span>
    <v-card flat ref="addManual">
      <div class="text-start v-btn--absolute" v-if="isManager">
        <v-icon small color="green darken-2"> mdi-arrow-up </v-icon>
        <span
          class="tip pointer"
          @click="$vuetify.goTo($refs.mainManual, options)"
          >к справочнику ГВС</span
        >
      </div>
      <div class="text-end" v-if="isManager">
        <span class="tip pointer" @click="changeAddOpen">{{
          openOrgText
        }}</span>
      </div>
      <ReferenceTip v-if="isManager" class="mb-3 mt-1">
        Выгрузка справочника ЭО в формате Excel доступна внизу таблицы, кнопка
        "Выгрузить справочник"
      </ReferenceTip>
      <v-expand-transition v-if="isManager">
        <div v-show="isAddOpen">
          <ReferenceTip
            >Для обновления справочника ЭО необходимо выбрать файл (*.xlsx),
            нажать "Обновить справочник"<br />В результате обновления произойдет
            полная замена текущего справочника</ReferenceTip
          >

          <UpdateOrgManual />
        </div>
      </v-expand-transition>
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="addSearch"
          prepend-inner-icon="mdi-magnify"
          label="Поиск по названию"
          single-line
          clearable
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        :headers="orgHeaders"
        :items="changedOrg"
        :items-per-page="-1"
        hide-default-footer
        :loading="loading"
        loading-text="Справочник загружается"
        :search="addSearch"
      >
        <template #[`item.name`]="{ value }">
          <span :inner-html.prop="value | highlight(addSearch)" />
        </template>
        <template v-slot:footer v-if="isManager">
          <div class="d-flex align-end justify-end">
            <ExportSummary
              hide-matched
              class="my-3"
              v-if="changedOrg && !loading"
              :storedData="changedOrg"
              :class-manual="'exp-org'"
            />
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ReferenceTip from '../../ReferenceTip'
import UpdateGvsManual from '@/components/dict/summary/allForms/UpdateGvsManual'
import UpdateOrgManual from '@/components/dict/summary/allForms/UpdateOrgManual'
import ExportSummary from '@/components/dict/summary/ExportSummary'

export default {
  name: 'GvsExpTable',
  components: {
    ExportSummary,
    UpdateOrgManual,
    UpdateGvsManual,
    ReferenceTip,
  },
  data() {
    return {
      loading: true,
      search: '',
      addSearch: '',
      sheets: [],
      isOpen: false,
      isAddOpen: false,
      gvsHeaders: [
        {
          text: '№',
          value: 'id',
          width: '5px',
          sortable: false,
          cellClass: 'text--disabled',
          filterable: false,
        },
        {
          text: 'ФИО',
          value: 'fio',
          sortable: false,
          cellClass: 'font-weight-bold ',
        },
        {
          text: 'Должность',
          align: 'justify',
          value: 'post',
          cellClass: 'py-2 red-line',
          filterable: false,
        },
      ],
      orgHeaders: [
        {
          text: '№',
          width: '5px',
          value: 'id',
          sortable: false,
          cellClass: 'text--disabled',
          filterable: false,
        },
        {
          text: 'Название экспертной организаций',
          value: 'name',
          sortable: true,
        },
      ],
      table_footer_options: {
        itemsPerPageOptions: [20, 50, 100, -1],
      },
    }
  },

  async mounted() {
    this.loading = true
    try {
      await Promise.all([this.GET_GVS_DICT(), this.GET_ORG_DICT()])
    } finally {
      this.loading = false
    }
  },

  computed: {
    ...mapGetters(['isManager', 'dictGvs', 'dictOrg']),
    //options для скролла до справочника
    options() {
      return {
        duration: 500,
        offset: 70,
        easing: 'easeInOutCubic',
      }
    },
    openGvsText() {
      return this.isOpen ? 'Скрыть' : 'Обновить справочник ГВС'
    },
    openOrgText() {
      return this.isAddOpen ? 'Скрыть' : 'Обновить справочник ЭО'
    },
    changedOrg() {
      return (
        this.dictOrg.map((item, index) => ({ ...item, id: index + 1 })) || []
      )
    },
    changedGVS() {
      return (
        this.dictGvs.map((item, index) => ({ ...item, id: index + 1 })) || []
      )
    },
  },
  methods: {
    ...mapActions(['GET_GVS_DICT', 'GET_ORG_DICT']),
    changeOpen() {
      this.isOpen = !this.isOpen
    },
    changeAddOpen() {
      this.isAddOpen = !this.isAddOpen
    },
  },
}
</script>
