var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"caption text-right text--secondary"},[_vm._v(" "+_vm._s(_vm.dictMedicineDescription)+" ")]),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-switch',{attrs:{"label":"ЖВНЛП","title":"Показывать только ЖВНЛП","hide-details":""},model:{value:(_vm.jvnlp),callback:function ($$v) {_vm.jvnlp=$$v},expression:"jvnlp"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Поиск по АТХ / МНН / ЛФ","single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"dense":"","search":_vm.search,"headers":_vm.filteredHeaders,"items":_vm.filteredDictMedicine,"items-per-page":100,"footerProps":_vm.table_footer_options,"loading":_vm.loading,"loading-text":"Справочник загружается","show-expand":"","item-key":"id","sort-by":"innLf"},on:{"pagination":_vm.pagination},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.pageStart + index + 1)+".")])]}},{key:"item.innInternal",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium",domProps:{"innerHTML":_vm._f("highlight")(item.innInternal,_vm.search)}}),_c('br'),_c('span',{staticClass:"text--secondary",domProps:{"innerHTML":_vm._f("highlight")(item.dosageFormInternal,_vm.search)}})]}},{key:"item.dosageFormInternal",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.regNr)+" ")]}},{key:"item.isJVNLP",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? 'Да' : '')+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',{staticClass:"my-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-subtitle-2"},[_vm._v(" Официальные данные (ГРЛС) ")]),_c('v-col',{staticClass:"text-subtitle-2 text-right"},[_vm._v(" АТХ: "),_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(item.atxCode.join(', ')))])])],1),(item.innOficialList.length > 0)?_c('v-row',[_c('v-col',{staticClass:"text-right font-weight-bold",attrs:{"cols":"1"}},[_vm._v(" Название ")]),_c('v-col',{staticClass:"text-left",attrs:{"cols":"11"}},_vm._l((item.innOficialList),function(item,index){return _c('span',{key:index},[_vm._v(_vm._s(item))])}),0)],1):_vm._e(),(item.regNrList.length > 0)?_c('v-row',[_c('v-col',{staticClass:"text-right font-weight-bold",attrs:{"cols":"1"}},[_vm._v(" РУ ")]),_c('v-col',{staticClass:"text-left",attrs:{"cols":"11"}},[_c('span',[_vm._v(_vm._s(item.regNrList.join(', ')))])])],1):_vm._e(),(item.dosageFormOficialList.length > 0)?_c('v-row',[_c('v-col',{staticClass:"text-right font-weight-bold",attrs:{"cols":"1"}},[_vm._v(" ЛФ ")]),_c('v-col',{staticClass:"text-left",attrs:{"cols":"11"}},[_c('span',[_vm._v(_vm._s(item.dosageFormOficialList.join(', ')))])])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-subtitle-2"},[_vm._v(" Обращение / госзакупки ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"11","offset":"1"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.groupHeaders,"items":_vm.getByYear(item),"item-key":"id","disable-sort":"","hide-default-footer":"","disable-pagination":"","dense":""}})],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }