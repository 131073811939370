var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"flat":""}},[(_vm.companies && _vm.isManager)?_c('ReferenceTip',{staticClass:"py-5 mt-2",attrs:{"label":"Конфликт интересов. Добавьте сотрудников в компанию для исключения их из перечня подписантов для предложений PLP"}}):_vm._e(),_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Поиск по компаниям","single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"items":_vm.companies,"headers":_vm.headers,"search":_vm.search,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":_vm.isManager,"hide-default-footer":"","disable-pagination":"","item-key":"id","no-results-text":"Ничего не нашлось","loading":!_vm.companiesReady,"loading-text":"Список компаний загружается..."},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([(_vm.isManager)?{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.openForAdd}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Добавить компанию ")],1)],1)]},proxy:true}:null,{key:"item.id",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1)+".")])]}},{key:"item.name",fn:function(ref){
var value = ref.value;
return [_c('span',{domProps:{"innerHTML":_vm._f("highlight")(value,_vm.search)}})]}},{key:"item.description",fn:function(ref){
var value = ref.value;
return [_c('span',{domProps:{"innerHTML":_vm._f("highlight")(value,_vm.search)}})]}},(_vm.isManager)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-btn',{attrs:{"fab":"","small":"","depressed":"","title":"Редактировать описание компании","color":"transparent"},on:{"click":function($event){return _vm.openForEdit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}:null,(_vm.isManager)?{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('CompanyUsersTable',{attrs:{"company":item}})],1)]}}:null],null,true)})],1),(_vm.showCompanyForm)?_c('CompanyForm',{attrs:{"company":_vm.item},on:{"close-company-form":_vm.closeCompanyForm}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }