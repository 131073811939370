<template>
  <div>
    <v-data-table
      :items="company.users"
      :headers="usersHeaders"
      hide-default-footer
      disable-pagination
      item-key="id"
      no-data-text="Нет сотрудников с конфликтом интересов для данной компании"
      :loading="loadingCompanyUsers"
      class="my-2"
      v-if="!error_message"
    >
      <template v-slot:[`item.fio`]="{ value }">
        <span :inner-html.prop="value | shortFIO" />
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-end my-3">
          <v-btn
            color="accent"
            fab
            small
            @click="openAddUser"
            :title="`Добавить для компании &quot;${company.name}&quot; конфликт интересов`"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </div>
      </template>
      <!-- Слот - действия в таблице пользователей (удалить) -->
      <template v-slot:[`item.actions`]="{ item }">
        <div class="actionsUser">
          <btn-with-confirm
            icon
            icon-main="mdi-delete-forever-outline"
            title="Удалить конфликт интересов"
            @click="deleteUserFromCompany(item)"
            :disabled="deleteUserInProgress"
          >
          </btn-with-confirm>
        </div>
      </template>
    </v-data-table>
    <v-alert type="error" v-if="error_message" class="mt-2" outlined>
      <div>Произошла ошибка при работе с компанией</div>
      <div>
        {{ error_message }}
      </div>
    </v-alert>
    <AddCompanyUserForm
      v-if="showAddCompanyUserForm"
      :company="company"
      @close-add-user-dialog="closeAddUserDialog"
    />
  </div>
</template>

<script>
import BtnWithConfirm from '@/components/buttons/btnWithConfirm'
import { mapActions } from 'vuex'
import AddCompanyUserForm from '@/components/dict/companies/allForms/AddCompanyUserForm'
export default {
  name: 'CompanyUsersTable',
  components: { AddCompanyUserForm, BtnWithConfirm },
  props: {
    // объект компании, в которую будем добавлять юзеров
    company: {
      type: Object,
      required: true,
    },
  },
  async created() {
    await this.getUsersForCompany()
  },
  data: () => ({
    usersHeaders: [
      { text: 'ФИО', value: 'fio' },
      { text: 'Должность', value: 'position' },
      { text: 'Действия', value: 'actions', width: '10px', sortable: false },
    ],
    error_message: '',
    loadingCompanyUsers: false,
    deleteUserInProgress: false,
    showAddCompanyUserForm: false,
  }),
  methods: {
    ...mapActions(['delete_user_from_company', 'get_company']),
    openAddUser() {
      this.showAddCompanyUserForm = true
    },
    closeAddUserDialog() {
      this.showAddCompanyUserForm = false
    },
    async getUsersForCompany() {
      // по открытию строки подгружаем юзеров конкретной компании по её id
      // value - true, значит открыл строку, false - закрыл строку

      if (this.company.users) return

      this.error_message = ''
      this.loadingCompanyUsers = true
      try {
        await this.get_company(this.company.id)
      } catch (err) {
        this.error_message = err
      } finally {
        this.loadingCompanyUsers = false
      }
    },
    async deleteUserFromCompany(user) {
      this.deleteUserInProgress = true
      this.error_message = ''
      try {
        const userId = user.id
        const companyId = this.company.id
        await this.delete_user_from_company({ userId, companyId })
      } catch (err) {
        this.error_message = err
      } finally {
        this.deleteUserInProgress = false
      }
    },
  },
}
</script>

<style scoped>
.v-data-table >>> tr .actionsUser {
  display: none !important;
}
.v-data-table >>> tr:hover .actionsUser {
  display: flex !important;
}
</style>
