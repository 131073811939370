<template>
  <CrTable />
</template>

<script>
import CrTable from '@/components/dict/cr/CrTable.vue'
export default {
  name: 'ReferencesCr',
  components: { CrTable },
}
</script>

<style scoped></style>
