<template>
  <v-dialog width="750px" persistent :value="true">
    <v-card class="pa-4">
      <v-form @submit.prevent="submitHandler">
        <v-card-title>{{ actionTitle }} компанию</v-card-title>
        <v-card-text>
          <v-text-field
            label="Наименование"
            v-model="formData.name"
            required
            class="mb-2"
          />
          <v-text-field
            label="Описание"
            v-model="formData.description"
            required
            class="mb-2"
          />
          <v-divider class="mb-3" />
          <v-textarea
            label="Синононимы компаний"
            v-model="companyTitles"
            class="mb-2 nowrap"
            rows="10"
            hint="Список синонимов компаний, новый синоним с новой строки"
          />
          <v-alert
            v-if="error_message"
            dismissible
            type="error"
            outlined
            class="mb-0"
            ><span v-html="error_message"
          /></v-alert>
          <v-alert
            v-if="showDeleteCompanyConfirmation"
            type="error"
            outlined
            class="mb-0"
          >
            Внимание! Вы точно хотите удалить эту компанию?
            <template #append>
              <v-btn
                color="error"
                class="mx-4"
                @click="deleteCompany(formData.id)"
                small
                :loading="deleteCompanyInProgress"
                :disabled="deleteCompanyInProgress"
                >Удалить</v-btn
              >
              <v-btn @click="hideConfirmation" small>Отменить</v-btn>
            </template>
          </v-alert>
        </v-card-text>
        <v-card-actions v-if="!showDeleteCompanyConfirmation">
          <v-btn
            type="submit"
            color="primary"
            class="mr-4"
            small
            :loading="saveCompanyInProgress"
            :disabled="saveCompanyInProgress"
            >Сохранить</v-btn
          >
          <v-btn @click="closeDialog" class="mr-4" small>Отменить</v-btn>
          <v-spacer />
          <v-btn
            v-if="company && !error_message"
            color="error"
            class="mr-2 float-right"
            @click="showDeleteCompany"
            small
            >Удалить</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { copyObjectBy } from '@/lib/objects'

const TEMPLATE_FORM_DATA = {
  id: null,
  name: '',
  description: '',
  data: null,
}

export default {
  name: 'CompanyForm',
  props: {
    // объект самой компании, если открыли для редактирования
    company: {
      type: Object,
      default: null,
    },
  },
  async created() {
    // если пришла компания в пропс, значит открыли на редактирование, подгружаем инфу для компании
    if (this.company) {
      this.error_message = ''
      try {
        await this.openForEdit(this.company)
      } catch (err) {
        this.error_message = `Произошла ошибка при загрузки данных компании: <br/><i>${err}</i>`
      }
    }
    this.dialog = true
  },
  data: () => ({
    formData: TEMPLATE_FORM_DATA,
    error_message: '',
    showDeleteCompanyConfirmation: false,
    saveCompanyInProgress: false,
    deleteCompanyInProgress: false,
  }),
  computed: {
    actionTitle() {
      return this.isOpenForAdd ? 'Добавить' : 'Редактировать'
    },
    companyTitles: {
      get: function () {
        return this.formData?.data?.titles?.join('\n') || ''
      },
      set: function (newTitles) {
        let { titles, ...data } = this.formData.data ?? {}
        // убираем все пустые строки FIXME
        titles = newTitles.split('\n').filter(title => title)
        this.formData.data = { ...data, titles }
      },
    },
  },
  methods: {
    ...mapActions([
      'get_company',
      'add_company',
      'save_company',
      'delete_company',
    ]),
    closeDialog() {
      this.formData = {}
      this.showDeleteCompanyConfirmation = false
      this.$emit('close-company-form')
    },
    async openForEdit(company) {
      if (company.users) {
        this.formData = copyObjectBy(TEMPLATE_FORM_DATA, company)
        return
      }
      const companyData = await this.get_company(company.id)
      this.formData = copyObjectBy(TEMPLATE_FORM_DATA, companyData)
    },
    submitHandler() {
      this.saveCompany()
    },
    async saveCompany() {
      this.error_message = ''
      this.saveCompanyInProgress = true
      try {
        if (!this.company) {
          // добавление новой компании
          await this.add_company(this.formData)
          this.$toast.success('Компания добавлена')
        } else {
          // сохранение компании
          await this.save_company(this.formData)
          this.$toast.success('Сохранено.')
        }
        this.formData = {}
        this.closeDialog()
      } catch (err) {
        this.error_message = `Произошла ошибка при сохранении: <br/><i>${err}</i>`
      } finally {
        this.saveCompanyInProgress = false
      }
    },
    showDeleteCompany() {
      this.showDeleteCompanyConfirmation = true
    },
    hideConfirmation() {
      this.error_message = ''
      this.showDeleteCompanyConfirmation = false
    },
    async deleteCompany(id) {
      this.deleteCompanyInProgress = true
      this.error_message = ''
      try {
        await this.delete_company(id)
        this.$toast.success('Успешно удален')
        this.closeDialog()
      } catch (err) {
        this.error_message = `Произошла ошибка при удалении: <br/><i>${err}</i>`
      } finally {
        this.deleteCompanyInProgress = false
      }
    },
  },
}
</script>

<style scoped>
.nowrap >>> textarea {
  white-space: pre;
  overflow: auto;
}
</style>
