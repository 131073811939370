<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col cols="8">
        <v-file-input
          accept=".xlsx"
          show-size
          hide-details
          @change="selectFile"
          label="Выберете файл справочника в формате Excel"
        />
      </v-col>
      <v-col cols="4" class="d-flex align-end justify-end">
        <v-btn
          @click="updateManual"
          color="success"
          :disabled="!file"
          :loading="updating"
          ><v-icon left>mdi-update</v-icon>Обновить справочник
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import apiCall from '@/lib/axiosApi'

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4))
      }
      resolve(encoded)
    }
    reader.onerror = error => reject(error)
  })

export default {
  name: 'UpdateForm',
  data: () => ({
    loading: true,
    file: null,
    updating: false,
  }),
  methods: {
    ...mapActions(['LOAD_KEI_TEMPLATE', 'SET_ERROR']),
    ...mapMutations(['SET_ERROR']),

    async loadDict() {
      this.loading = true
      try {
        await this.LOAD_KEI_TEMPLATE()
      } finally {
        this.loading = false
      }
    },

    async updateManual() {
      this.updating = true
      try {
        // отправка на сервер
        await apiCall(
          'dict/templates/kei/import',
          {
            original_filename: this.file.name,
            data: await toBase64(this.file),
          },
          'POST'
        )
        this.$toast.success('Успешно сохранено')
        this.loadDict()
      } catch (e) {
        this.SET_ERROR({ head: 'Обновление справочника', e })
      } finally {
        this.file = null
        this.updating = false
      }
    },
    selectFile(file) {
      this.file = file
    },
  },
}
</script>
