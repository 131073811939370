<template>
  <AvbTable />
</template>

<script>
import AvbTable from '@/components/dict/avb/AvbTable'

export default {
  name: 'ReferencesAvb',
  components: { AvbTable },
}
</script>
