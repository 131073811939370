<template>
  <GvsExpTable />
</template>

<script>
import GvsExpTable from '@/components/dict/summary/GvsExpTable'
export default {
  name: 'ReferencesSummary',
  components: { GvsExpTable },
}
</script>

<style scoped></style>
