<template>
  <KeiTable />
</template>

<script>
import KeiTable from '@/components/dict/kei/KeiTable'

export default {
  name: 'ReferencesKei',
  components: { KeiTable },
}
</script>
