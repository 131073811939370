<template>
  <div>
    <div class="caption text-right text--secondary mt-6">
      {{ dictIndicationsDescription }}
    </div>
    <div class="caption text-right text--secondary">
      {{
        dictRefresh
          ? '... проверка обновления справочника на НСИ'
          : dictAthDescription
      }}
      <v-btn
        v-if="isManager"
        icon
        small
        title="Проверить и обновить МКБ-10 на НСИ"
        class="ml-1"
        :loading="dictRefresh"
        @click="dictRefreshClick"
        ><v-icon small>mdi-refresh</v-icon></v-btn
      >
    </div>

    <div v-if="isIndicationsEditor" class="d-flex justify-end pa-3">
      <v-switch
        v-model="editMode"
        label="Редактировать"
        hide-details
        class="my-0"
      />
    </div>
    <MedicineIndicationsTable
      readonly
      :edit-mode="isIndicationsEditor && editMode"
    />
  </div>
</template>

<script>
import MedicineIndicationsTable from '@/components/dict/indications/MedicineIndicationsTable'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ReferencesIndications',
  components: { MedicineIndicationsTable },
  data() {
    return {
      editMode: false,
      dictRefresh: false,
    }
  },
  computed: {
    ...mapGetters([
      'dictIndicationsDescription',
      'isIndicationsEditor',
      'dictAthDescription',
      'isManager',
    ]),
  },
  created() {
    this.editMode =
      this.isIndicationsEditor &&
      (JSON.parse(localStorage.getItem('dictIndicationsEditMode')) ?? false)
  },
  async mounted() {
    this.dictRefresh = true
    try {
      await this.GET_ATH_DICT()
    } finally {
      this.dictRefresh = false
    }
  },
  watch: {
    editMode(newVal) {
      localStorage.setItem('dictIndicationsEditMode', JSON.stringify(newVal))
    },
  },
  methods: {
    ...mapActions(['GET_ATH_DICT', 'IMPORT_ATH_DICT']),

    async dictRefreshClick() {
      this.dictRefresh = true
      try {
        const message = await this.IMPORT_ATH_DICT()
        this.$toast(message)
      } finally {
        this.dictRefresh = false
      }
    },
  },
}
</script>

<style scoped></style>
