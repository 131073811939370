<template>
  <v-container fluid>
    <div class="caption text-right text--secondary">
      {{ dictMedicineDescription }}
    </div>
    <v-card flat>
      <v-card-title>
        <v-switch
          v-model="jvnlp"
          label="ЖВНЛП"
          title="Показывать только ЖВНЛП"
          hide-details
        />
        <v-spacer />
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Поиск по АТХ / МНН / ЛФ"
          single-line
          clearable
          hide-details
        />
      </v-card-title>

      <!-- Таблица с данными -->
      <v-data-table
        dense
        :search="search"
        :headers="filteredHeaders"
        :items="filteredDictMedicine"
        :items-per-page="100"
        :footerProps="table_footer_options"
        :loading="loading"
        loading-text="Справочник загружается"
        show-expand
        item-key="id"
        sort-by="innLf"
        @pagination="pagination"
      >
        <template #[`item.index`]="{ index }">
          <span>{{ pageStart + index + 1 }}.</span>
        </template>
        <template v-slot:[`item.innInternal`]="{ item }">
          <span
            class="font-weight-medium"
            :inner-html.prop="item.innInternal | highlight(search)"
          /><br /><span
            class="text--secondary"
            :inner-html.prop="item.dosageFormInternal | highlight(search)"
          />
        </template>

        <template v-slot:[`item.dosageFormInternal`]="{ item }"
          >{{ item.regNr }}
        </template>

        <template v-slot:[`item.isJVNLP`]="{ value }">
          {{ value ? 'Да' : '' }}
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container fluid class="my-4">
              <v-row>
                <v-col class="text-subtitle-2">
                  Официальные данные (ГРЛС)
                </v-col>
                <v-col class="text-subtitle-2 text-right">
                  АТХ:
                  <span class="text-body-2">{{ item.atxCode.join(', ') }}</span>
                </v-col>
              </v-row>
              <v-row v-if="item.innOficialList.length > 0">
                <v-col cols="1" class="text-right font-weight-bold">
                  Название
                </v-col>
                <v-col cols="11" class="text-left">
                  <span
                    v-for="(item, index) in item.innOficialList"
                    :key="index"
                    >{{ item }}</span
                  >
                </v-col>
              </v-row>
              <v-row v-if="item.regNrList.length > 0">
                <v-col cols="1" class="text-right font-weight-bold"> РУ </v-col>
                <v-col cols="11" class="text-left">
                  <span>{{ item.regNrList.join(', ') }}</span>
                </v-col>
              </v-row>
              <v-row v-if="item.dosageFormOficialList.length > 0">
                <v-col cols="1" class="text-right font-weight-bold"> ЛФ </v-col>
                <v-col cols="11" class="text-left">
                  <span>{{ item.dosageFormOficialList.join(', ') }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-subtitle-2"> Обращение / госзакупки </v-col>
              </v-row>
              <v-row>
                <v-col cols="11" offset="1">
                  <v-data-table
                    :headers="groupHeaders"
                    :items="getByYear(item)"
                    item-key="id"
                    disable-sort
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                    dense
                  >
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ntl } from '@/lib/strUtils'

export default {
  name: 'MedicineTable',
  async mounted() {
    this.loading = true
    try {
      await this.GET_MEDICINE_DICT()
    } finally {
      this.loading = false
    }
  },
  data() {
    return {
      loading: true,
      search: '',
      sheets: [],
      file: null,
      isOpen: false,
      jvnlp: true,
      pageStart: 0,
      groupHeaders: [
        {
          text: 'Год',
          value: 'year',
          width: '75px',
          align: 'center',
          cellClass: 'text--disabled font-weight-bold',
        },
        { text: 'Обращение', value: 'q', align: 'center' },
        { text: 'Упаковки ГЗ', value: 'p', align: 'center' },
        { text: 'Стоимость ГЗ', value: 'pr', align: 'center' },
      ],
      headers: [
        {
          text: '№',
          value: 'index',
          width: '5px',
          sortable: false,
          cellClass: 'text--disabled',
          filterable: false,
        },
        {
          text: 'АТХ',
          value: 'atxGroupCode',
          sortable: true,
          cellClass: 'text--disabled',
          width: '75px',
        },
        {
          text: 'МНН/ЛФ',
          align: 'start',
          value: 'innInternal',
          sortable: true,
        },
        {
          text: 'ЖВНЛП',
          value: 'isJVNLP',
          sortable: false,
          align: 'center',
          cellClass: 'text-caption text--secondary',
          filterable: false,
          width: '100px',
        },
        {
          text: 'РУ',
          // в поле ЛВ выводим фактически РУ но имеем фильтр по полю)
          value: 'dosageFormInternal',
          sortable: false,
          align: 'center',
          cellClass: 'text-caption',
          width: '150px',
        },
        {
          text: 'Дата окончания РУ',
          value: 'regDateFin',
          sortable: false,
          align: 'center',
          cellClass: 'text-caption',
          filterable: false,
          width: '150px',
        },
        { text: '', value: 'data-table-expand', filterable: false },
      ],
      table_footer_options: {
        itemsPerPageOptions: [100, 200, 300, 500, -1],
      },
    }
  },
  methods: {
    ...mapActions(['GET_MEDICINE_DICT']),

    getByYear(item) {
      return [0, 1, 2].map(id => ({
        id,
        year: item.yearData - id ?? '-',
        q: ntl(item[`quantitybyYear${id}`], '-'),
        p: ntl(item[`countPackbyYear${id}`], '-'),
        pr: ntl(item[`contractCostbyYear${id}`], '-'),
      }))
    },
    changeOpen() {
      this.isOpen = !this.isOpen
    },
    pagination({ pageStart }) {
      this.pageStart = pageStart
    },
  },
  computed: {
    ...mapGetters(['dictMedicine', 'dictMedicineDescription']),
    openText() {
      return this.isOpen ? 'Скрыть' : 'Обновить справочник'
    },
    filteredHeaders() {
      return this.headers?.filter(
        ({ value }) => !this.jvnlp || value !== 'isJVNLP'
      )
    },
    filteredDictMedicine() {
      return (
        this.dictMedicine?.filter(({ isJVNLP }) => !this.jvnlp || isJVNLP) ?? []
      )
    },
  },
}
</script>

<style scoped>
.cell_hide {
  visibility: hidden !important;
}
</style>
