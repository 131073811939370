<template>
  <v-btn @click="loadManual" :loading="loading">
    <v-icon class="mr-2" color="green darken-4">mdi-microsoft-excel</v-icon>
    Выгрузить справочник
  </v-btn>
</template>

<script>
//компонент для выгрузки справочников КЭИ и АВБ
import Excel from '@/lib/excel'
import { mapMutations } from 'vuex'

export default {
  name: 'ExportSummary',
  data: () => ({
    loading: false,
    maxFilterCount: 1,
    headersGvs: [
      {
        name: 'id',
        label: '№',
        options: {
          width: 5,
          style: {
            alignment: {
              vertical: 'top',
            },
          },
        },
      },
      {
        name: 'fio',
        label: 'ФИО',
        options: {
          width: 40,
          style: {
            alignment: { wrapText: true, vertical: 'top' },
          },
        },
      },
      {
        name: 'post',
        label: 'Специальность',
        options: {
          width: 60,
          style: {
            alignment: { wrapText: true, vertical: 'top' },
          },
        },
      },
    ],
    headersExp: [
      {
        name: 'id',
        label: '№',
        options: {
          width: 5,
          style: {
            alignment: {
              vertical: 'top',
            },
          },
        },
      },
      {
        name: 'name',
        label: 'Название экспертной организации',
        options: {
          width: 50,
          style: {
            alignment: { wrapText: true, vertical: 'top' },
          },
        },
      },
    ],
  }),
  props: {
    storedData: {
      type: Array,
      required: true,
    },
    hideMatched: {
      type: Boolean,
      default: false,
    },
    classManual: {
      type: String,
      default: 'gvs',
    },
  },
  computed: {
    headersSummary() {
      return this.classManual === 'gvs' ? this.headersGvs : this.headersExp
    },
    nameManual() {
      return this.classManual === 'gvs' ? 'ГВС' : 'ЭО'
    },
  },
  methods: {
    ...mapMutations(['SET_ERROR']),

    async loadManual() {
      this.loading = true
      const changedStoreData = JSON.parse(JSON.stringify(this.storedData))
      try {
        await Excel.downloadXLSX({
          name: 'Лист 1',
          data: async () => {
            return changedStoreData
          },
          headers: this.headersSummary,
          fileName: `${this.nameManual}_от_${this.getTimestamp()}`,
        })
      } catch (e) {
        this.SET_ERROR({
          head: this.nameManual,
          text: `Выгрузка справочника ${this.nameManual}`,
          e,
        })
      } finally {
        this.loading = false
        this.maxFilterCount = 1
      }
    },
    getTimestamp() {
      const date = new Date()
      const addZero = v => (v.toString().length === 1 ? `0${v}` : v)
      const yyyy = date.getFullYear()
      const MM = addZero(date.getMonth() + 1)
      const dd = addZero(date.getDate())
      return `${dd}_${MM}_${yyyy}`
    },
  },
}
</script>
